@import url(fontiran.css);

@font-face {
  font-family: 'Exodar-outline';
  src: url(../../fonts/ttf/Exodar-Outline.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  font-family: iranyekan !important;
}

#logo {
  font-family: Exodar-outline !important;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(101, 101, 187) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
  border: 2px solid rgb(145, 118, 70);
}

/* Child Voice */
#edited-voice > div > .player {
  height: 42px;
  background: rgba(57, 192, 245, 0.8) !important;
  border-radius: 132px;
  padding: 4px;
  padding-right: 9px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.player {
  height: 42px;
  background: rgba(245, 158, 57, 0.8);
  border-radius: 132px;
  padding: 4px;
  padding-right: 9px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.player img.icon {
  width: 34px;
  height: auto;
}

.player img.bar {
  position: absolute;
  bottom: 2px;
  height: 40px;
  width: 88%;
  left: 36px;
}

.progressBarNew {
  margin: 0 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.progressBarNewAll {
  position: absolute;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  background-color: #dcdcdc;
  width: 100%;
}

.progressBarNewDone {
  border-radius: 4px;
  border: 4px solid #fff;
  background-color: #fff;
  position: absolute;
}

.progressBarNewDone.verify {
  border-radius: 4px;
  border: 1px solid #f59e39;
  background-color: #f59e39;
  position: absolute;
  width: 100%;
}

.progressBarTime {
  width: fit-content;
  font-family: inherit;
  font-size: 12px;
  color: #666666;
  margin: 0 2px;
}

/* pie chart */
.apexcharts-legend-marker {
  margin: 4px;
}